import React from 'react';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import styles from './FlatConditionBar.module.scss';

export default function FlatConditionBar({register}) {
  return (
    <>
      <p className={classNames(text_styles.body1_left, styles.title)}>
        Wie war der Zustand der Wohnung?
      </p>

      <div className={styles.container}>
        <input
          {...register('flat_condition')}
          type="radio"
          className={styles.radio_button}
          name="flat_condition"
          value="bad"
          id="button_1"
        />
        <label htmlFor="button_1">schlecht</label>
        <input
          {...register('flat_condition')}
          type="radio"
          className={styles.radio_button}
          name="flat_condition"
          value="poor"
          id="button_2"
        />
        <label htmlFor="button_2">mangelhaft</label>
        <input
          {...register('flat_condition')}
          type="radio"
          className={styles.radio_button}
          name="flat_condition"
          value="satisfactory"
          id="button_3"
        />
        <label htmlFor="button_3">befriedigend</label>
        <input
          {...register('flat_condition')}
          type="radio"
          className={styles.radio_button}
          name="flat_condition"
          value="good"
          id="button_4"
        />
        <label htmlFor="button_4">gut</label>
        <input
          {...register('flat_condition')}
          type="radio"
          className={styles.radio_button}
          name="flat_condition"
          value="very_good"
          id="button_5"
        />
        <label htmlFor="button_5">sehr gut</label>
      </div>
    </>
  );
}
