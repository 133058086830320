import React from 'react';
import classNames from 'classnames';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Icon from 'shared/components/Icon.js';

import {ReactComponent as WarningSvg} from './icon-warning.svg';
import {ReactComponent as InfoSvg} from './icon-info.svg';
import styles from './Footer.module.scss';

export default function Footer({
  isSubmitting,
  isDirty,
  onRelease,
  onSave,
  watch,
}) {
  const mandatory_questions_answered = watch([
    'open_debt',
    'open_litigation',
    'open_obligations',
    'statement_given',
    'flat_handed_over',
  ]).every((value) => typeof value === 'boolean');

  return (
    <div className={styles.footer}>
      {mandatory_questions_answered ? (
        <>
          <div className={styles.row}>
            <Icon className={styles.icon}>
              <InfoSvg />
            </Icon>
            <p className={text_styles.body1_bold_left}>
              Möchten Sie die hinterlegte Kaution wirklich freigeben?
            </p>
          </div>
          <p className={classNames(text_styles.body2, styles.footer_text)}>
            Nach der Freigabe wird der Vertrag mit dem Mieter unwiderruflich
            aufgelöst und eine weitere Inanspruchnahme ist ausgeschlossen.
          </p>
        </>
      ) : (
        <>
          <div className={styles.row}>
            <Icon className={styles.icon}>
              <WarningSvg />
            </Icon>
            <p className={text_styles.body1_bold_left}>
              Bitte unbedingt beachten vor der Freigabe:
            </p>
          </div>
          <p className={classNames(text_styles.body2, styles.footer_text)}>
            Wir empfehlen die vollständige Freigabe der Kaution erst, nachdem
            alle Punkte in der Freigabe-Checkliste erfolgreich bearbeitet wurden
            und hinter jedem Punkt das Verifiziert-Symbol aufleuchtet.
          </p>
        </>
      )}

      <div className={styles.spaced_row}>
        <Button
          type="submit"
          title="Kaution freigeben"
          loading={isSubmitting}
          onClick={onRelease}
        />
        <Button
          type="submit"
          title="Speichern, später freigeben"
          loading={isSubmitting}
          onClick={onSave}
          disabled={!isDirty}
        />
      </div>
    </div>
  );
}
