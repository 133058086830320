import React, {forwardRef} from 'react';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import de from 'react-phone-number-input/locale/de.json';

import text_styles from '../../styles/text_styles.module.scss';
import TextInput from '../TextInput.js';

import arrow_down from './icon-arrow-down.svg';
import styles from './PhoneNumberInput.module.scss';

function PhoneNumberInput(
  {
    label,
    placeholder = '...',
    className,
    labelClassName,
    error = '',
    onChange,
    readOnly = false,
    value,
    ...props
  },
  ref,
) {
  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <div
          className={classNames(
            text_styles.caption_left,
            styles.label,
            labelClassName,
          )}>
          {label}
        </div>
      )}
      {readOnly ? (
        <TextInput readOnly placeholder={placeholder} ref={ref} value={value} />
      ) : (
        <PhoneInput
          ref={ref}
          international
          defaultCountry="DE"
          labels={de}
          placeholder={placeholder}
          inputComponent={TextInput}
          countrySelectComponent={readOnly ? () => <></> : undefined}
          countrySelectProps={{arrowComponent: ArrowComponent}}
          flagUrl="/flags/3x2/{XX}.svg"
          // react-phone-number-input calls onChange with undefined when the value becomes falsy
          // react-hook-form sets the field to the default value when it is undefined
          // therefor we need this to prevent the default phone number to re-appear
          // when the user removes it. This is documented on react-phone-number-input
          // https://gitlab.com/catamphetamine/react-phone-number-input#use
          onChange={(value) => onChange(value ?? '')}
          value={value}
          {...props}
        />
      )}
      <p className={classNames(text_styles.caption_left, styles.error)}>
        {error}
      </p>
    </div>
  );
}

function ArrowComponent() {
  return <img src={arrow_down} alt="down arrow" height="24px" width="24px" />;
}

export default forwardRef(PhoneNumberInput);
