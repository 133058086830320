import React, {forwardRef} from 'react';
import classNames from 'classnames';

import {ReactComponent as CopySvg} from '../assets/copy.svg';
import text_styles from '../styles/text_styles.module.scss';
import {useNotify} from '../NotifyProvider.js';
import {copyText} from '../effects.js';

import TextInput from './TextInput.js';
import styles from './Input.module.scss';
import IconButton from './IconButton.js';

function Input(
  {
    label,
    placeholder = '...',
    inputClassName,
    className,
    error = '',
    readOnly,
    copyLabel,
    value,
    ...props
  },
  ref,
) {
  const {notify} = useNotify();

  return (
    <div
      className={classNames(
        readOnly ? styles.read_only_container : styles.container,
        className,
      )}>
      {label && (
        <div className={classNames(text_styles.caption_left, styles.label)}>
          {label}
        </div>
      )}
      <div className={styles.input_wrapper}>
        <TextInput
          ref={ref}
          className={inputClassName}
          placeholder={readOnly ? '–' : placeholder}
          readOnly={readOnly}
          value={value}
          {...props}
        />
        {copyLabel && (
          <IconButton
            onClick={() => copyText(copyLabel, value, notify)}
            className={styles.copy_icon}>
            <CopySvg />
          </IconButton>
        )}
      </div>
      {!readOnly && (
        <p className={classNames(text_styles.caption_left, styles.error)}>
          {error}
        </p>
      )}
    </div>
  );
}

export default forwardRef(Input);
