import React from 'react';
import {useTable} from 'react-table';
import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames';
import {formatDate} from 'shared/utils.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Icon from 'shared/components/Icon.js';
import {useHistory} from 'react-router-dom';

import {openTenantReport} from '../../effects.js';
import {ReactComponent as MoreVerticalSvg} from '../../assets/more_vertical.svg';
import TableHeaderButton from '../../components/table_header_button/TableHeaderButton.js';

import styles from './PropertyOwnersTable.module.scss';
import {ReactComponent as ManagerOwnerSvg} from './manager-owner.svg';

export default function PropertyOwnersTable({
  onOpen,
  property_owners,
  orderBy,
  sort_column,
  sort_direction,
}) {
  const {getTableProps, headerGroups, getTableBodyProps, rows, prepareRow} =
    useTable({columns, data: property_owners});

  return (
    <table className={styles.table} {...getTableProps()}>
      <thead className={styles.thead}>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th
                key={idx}
                className={classNames(styles.th, styles[column.id])}
                {...column.getHeaderProps()}>
                {column.sort ? (
                  <TableHeaderButton
                    orderBy={orderBy}
                    column={column}
                    sort_column={sort_column}
                    sort_direction={sort_direction}
                  />
                ) : (
                  <div className={styles.header}>{column.render('Header')}</div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          prepareRow(row);
          return <PropertyOwnerRow key={idx} row={row} onOpen={onOpen} />;
        })}
      </tbody>
    </table>
  );
}

function Menu({property_owner}) {
  return (
    <Dropdown>
      <Dropdown.Toggle as="button">
        <Icon>
          <MoreVerticalSvg />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item
          as="button"
          disabled={property_owner.number_of_active_deposit_guarantees <= 0}
          onClick={() => openTenantReport(property_owner)}>
          Bericht generieren
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function PropertyOwnerRow({row, onOpen}) {
  const history = useHistory();

  return (
    <tr className={styles.tr} {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === 'actions') {
          return (
            <td {...cell.getCellProps()}>
              <Menu property_owner={row.original} />
            </td>
          );
        }
        return (
          // getCellProps() has a key
          <td
            {...cell.getCellProps()}
            className={classNames(styles.pointer, styles[cell.column.id])}
            onClick={() => onOpen(row.original, history)}>
            <div
              className={classNames(
                text_styles[cell.column.text_styles],
                styles.cell,
              )}>
              <span>{cell.render('Cell')}</span>
            </div>
          </td>
        );
      })}
    </tr>
  );
}

export const columns = [
  {
    id: 'display_name',
    Header: 'Firma oder Name',
    accessor: ({
      display_name,
      is_manager,
      has_coowner,
      coowner_first_name,
      coowner_last_name,
    }) => {
      if (is_manager) {
        return (
          <div className={styles.is_manager}>
            <div className={styles.manager_display_name}>{display_name}</div>
            <Icon className={styles.manager_owner_icon}>
              <ManagerOwnerSvg />
            </Icon>
          </div>
        );
      }

      if (has_coowner) {
        return (
          <div>
            <p className={text_styles.body1_bold_left}>{display_name} &</p>
            <p className={text_styles.body1_italic}>
              {coowner_first_name} {coowner_last_name}
            </p>
          </div>
        );
      }

      return display_name;
    },
    text_styles: 'body1_bold_left',
    sort: 'display_name',
  },
  {
    id: 'contact_person',
    Header: 'Ansprechpartner',
    accessor: (row) => {
      if (!row.company_name) return '–';

      if (!row.first_name && !row.last_name) return '–';

      if (!row.first_name) return row.last_name;

      if (!row.last_name) return row.first_name;

      return `${row.first_name} ${row.last_name}`;
    },
    text_styles: 'body2',
    sort: 'contact_last_name',
  },
  {
    id: 'region',
    Header: 'Ort',
    accessor: 'region',
    text_styles: 'body2',
    sort: 'region',
  },
  {
    id: 'number_of_active_deposit_guarantees',
    Header: 'Verknüpfte Mieter',
    accessor: 'number_of_active_deposit_guarantees',
    text_styles: 'body2',
    sort: 'number_of_active_deposit_guarantees',
  },
  {
    id: 'created_at',
    Header: 'Erstellt',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
  {id: 'actions', Header: '', accessor: 'actions', text_styles: ''},
];
