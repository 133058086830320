import Input from 'shared/components/Input.js';
import SeparatorLine from 'shared/components/SeparatorLine.js';
import {Controller} from 'react-hook-form';

import RadioToggle from '../../components/RadioToggle.js';

import styles from './TenantData.module.scss';

export default function TenantData({errors, register, control, watch}) {
  return (
    <>
      <div className={styles.row}>
        <Controller
          control={control}
          name="tenant_type"
          render={({field: {onChange, name}}) => (
            <RadioToggle
              name={name}
              option_1="individual"
              option_1_label="Privatperson"
              option_2="company"
              option_2_label="Firma"
              onChange={onChange}
              watch={watch}
            />
          )}
        />
      </div>

      {watch('tenant_type') === 'company' && (
        <Input
          label="Name und Rechtsform"
          error={errors.company_name?.message}
          placeholder="z.B. Feinkost Schmitt GmbH"
          {...register('company_name')}
        />
      )}

      <div className={styles.row}>
        <Input
          label={
            (watch('tenant_type') === 'individual' && 'Vorname Mieter 1') ||
            'Vorname Geschäftsführer 1'
          }
          error={errors.tenant_1_first_name?.message}
          {...register('tenant_1_first_name')}
        />
        <Input
          label={
            (watch('tenant_type') === 'individual' && 'Nachname Mieter 1') ||
            'Nachname Geschäftsführer 1'
          }
          error={errors.tenant_1_last_name?.message}
          {...register('tenant_1_last_name')}
        />
      </div>

      <div className={styles.row}>
        <Input
          label={
            (watch('tenant_type') === 'individual' &&
              'Vorname Mieter 2 (optional)') ||
            'Vorname Geschäftsführer 2 (optional)'
          }
          error={errors.tenant_2_first_name?.message}
          {...register('tenant_2_first_name')}
        />
        <Input
          label={
            (watch('tenant_type') === 'individual' &&
              'Nachname Mieter 2 (optional)') ||
            'Nachname Geschäftsführer 2 (optional)'
          }
          error={errors.tenant_2_last_name?.message}
          {...register('tenant_2_last_name')}
        />
      </div>

      <SeparatorLine className={styles.separator} />

      <Input
        type="text"
        label="Beschreibung (optional)"
        placeholder="z. B. ID-123"
        error={errors.given_reference?.message}
        {...register('given_reference')}
        className={styles.given_reference}
      />
    </>
  );
}
