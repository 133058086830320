import React from 'react';
import {useTable} from 'react-table';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import Spinner from '../../components/spinner/Spinner.js';

import {columns} from './DepositsTable.js';
import table_styles from './DepositsTable.module.scss';
import styles from './DepositsPlaceholder.module.scss';

export default function DepositsPlaceholder({search_query, loading}) {
  const {getTableProps, headerGroups, getTableBodyProps} = useTable({
    columns,
    data: [],
  });
  const placeholder_rows = Array.from({length: 3}, (_, i) => i);

  return (
    <>
      <table className={table_styles.table} {...getTableProps()}>
        <thead className={table_styles.thead}>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                return (
                  <th
                    key={i}
                    className={classNames(
                      table_styles.th,
                      table_styles[column.id],
                    )}
                    {...column.getHeaderProps()}>
                    <div className={table_styles.header}>
                      {column.render('Header')}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {placeholder_rows.map((i) => {
            return (
              <tr
                key={i}
                className={classNames(
                  table_styles.tr,
                  table_styles[`placeholder_row__${i}`],
                )}>
                {columns.map((column, i) => {
                  if (column.id === 'actions') return null;

                  const show_deposit_placeholder = column.id === 'deposit';

                  return (
                    <td key={i}>
                      <div
                        className={classNames(
                          styles.empty,
                          show_deposit_placeholder && styles.empty_deposit,
                        )}>
                        <div className={table_styles[column.id]} />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className={classNames(text_styles.body2_centered, table_styles.text)}>
        {getPlaceholderText({search_query, loading})}
      </div>
      {loading && <Spinner className={styles.spinner} />}
    </>
  );
}

const PLACEHOLDER_TEXT_NONE_CREATED = `Sie haben noch keine Mieter angelegt.
Sie können Ihren ersten Mieter per Klick auf „Mieter erstellen“ anlegen.`;

const PLACEHOLDER_TEXT_NO_MATCHES = `Wir konnten keine Einträge auf Ihre Suchanfrage finden.
Bitte überprüfen Sie Ihre Suchanfrage auf Schreibfehler oder ändern Sie diese ab.
Es werden nur die Felder mit dem Namen, Vornamen und der Adresse durchsucht.`;

const PLACEHOLDER_TEXT_LOADING =
  'Einen Moment Bitte, wir durchsuchen Eintrag...';

function getPlaceholderText({search_query, loading}) {
  if (loading) {
    return PLACEHOLDER_TEXT_LOADING;
  } else if (search_query.length > 0) {
    return PLACEHOLDER_TEXT_NO_MATCHES;
  }
  return PLACEHOLDER_TEXT_NONE_CREATED;
}
