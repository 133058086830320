import classNames from 'classnames/bind';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {useHistory} from 'react-router-dom';
import {MANAGER_PATHS} from 'shared/constants.js';

import Dialog from '../../components/dialog/Dialog.js';
import {closeDialog} from '../../effects.js';

import next_1 from './deposit-next1@2x.png';
import next_2 from './deposit-next2@2x.png';
import styles from './DepositGuaranteeInfoDialog.module.scss';

export default function DepositGuaranteeInfoDialog({deposit, ...props}) {
  const history = useHistory();

  function showDepositGuarantee() {
    closeDialog('deposit_guarantee_info');
    history.push(`${MANAGER_PATHS.DepositsScreen}/${deposit.id}`);
  }

  const footer = (
    <div className={styles.footer}>
      <div className={styles.row} />
      <Button
        title="Daten anzeigen"
        className={styles.margin_right}
        onClick={showDepositGuarantee}
        data-testid="show_data"
      />
      <Button
        title="Fertig"
        onClick={() => closeDialog('deposit_guarantee_info')}
      />
    </div>
  );

  return (
    <Dialog
      title="Nächste Schritte"
      footer={footer}
      className={styles.container}
      {...props}>
      <div className={styles.info_container}>
        <div>
          <div className={styles.next_image}>
            <img
              src={next_1}
              alt="information"
              className={styles.next_image}
              width="266px"
              height="185px"
            />
          </div>
          <div className={styles.info}>
            <div
              className={classNames(
                text_styles.body1_bold_left,
                styles.info_title,
              )}>
              Der Mieter erhält einen Link zu unserem Fragebogen
            </div>
            <div className={text_styles.caption_left}>
              Der Mieter kann alle Fragen direkt am Handy oder am PC
              beantworten.
            </div>
          </div>
        </div>
        <div>
          <div className={styles.next_image}>
            <img src={next_2} alt="information" width="266px" height="185px" />
          </div>
          <div className={styles.info}>
            <div
              className={classNames(
                text_styles.body1_bold_left,
                styles.info_title,
              )}>
              Wir aktivieren Ihren Kautionsschutz
            </div>
            <div className={text_styles.caption_left}>
              Nach erfolgreicher Überprüfung kann der Mieter unseren
              Kautionsschutz nutzen. Ab Einzug können Sie über die Kaution
              verfügen.
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
