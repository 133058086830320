import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'SHOW_MODAL': {
      draft.visible = true;
      draft.name = action.name;
      draft.props = action.props;
      break;
    }
    case 'HIDE_MODAL': {
      draft.visible = false;
      break;
    }
    case 'MODAL_CLOSED': {
      draft.name = '';
      draft.props = undefined;
      draft.visible = false;
    }
  }
}, {});
