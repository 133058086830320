import {formatPrice, getClaimDetails, formatDate} from '../utils.js';
import {DEPOSIT_SUBSTATUS} from '../constants.js';

export default function translateSubStatus({
  sub_status,
  deposit_guarantee_start_date,
  claims,
  account_balance_cents,
}) {
  switch (sub_status) {
    case DEPOSIT_SUBSTATUS.status_error: {
      return '';
    }
    case DEPOSIT_SUBSTATUS.account_balance_empty:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_part:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_full: {
      let balance = '';
      try {
        balance = formatPrice(account_balance_cents);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      return balance;
    }
    case DEPOSIT_SUBSTATUS.disabled: {
      return '';
    }
    case DEPOSIT_SUBSTATUS.released: {
      return 'Freigegeben';
    }
    case DEPOSIT_SUBSTATUS.rejected_by_tenant: {
      return 'Abgelehnt vom Mieter';
    }
    case DEPOSIT_SUBSTATUS.rejected_by_getmomo: {
      return 'Abgelehnt von Getmomo';
    }
    case DEPOSIT_SUBSTATUS.some_claims_paid_out:
    case DEPOSIT_SUBSTATUS.all_claims_paid_out: {
      if (!claims) {
        throw new Error('Claims are required');
      }
      const {total_paid_out_claims_cents} = getClaimDetails(claims);
      return `Ausgezahlt ${formatPrice(total_paid_out_claims_cents)}`;
    }
    case DEPOSIT_SUBSTATUS.claim_requested: {
      if (!claims) {
        throw new Error('Claims are required');
      }
      const {total_open_claim_cents} = getClaimDetails(claims);
      return `Angefragt ${formatPrice(total_open_claim_cents)}`;
    }
    case DEPOSIT_SUBSTATUS.active_in_future: {
      if (!deposit_guarantee_start_date) {
        throw new Error('deposit_guarantee_start_date is required');
      }
      return `Aktiv ab ${formatDate(deposit_guarantee_start_date)}`;
    }
    case DEPOSIT_SUBSTATUS.active: {
      return 'Aktiv';
    }
    case DEPOSIT_SUBSTATUS.video_identification_required: {
      return 'Video-Identifizierung fehlt';
    }
    case DEPOSIT_SUBSTATUS.invitation_sent: {
      return 'Einladung gesendet';
    }
    default: {
      return '';
    }
  }
}
