import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'SHOW_DIALOG': {
      draft[action.name] = {
        visible: true,
        props: action.props,
        mounted: true,
      };
      draft.open_dialogs.unshift(action.name);
      break;
    }
    case 'HIDE_DIALOG': {
      draft[action.name].visible = false;
      break;
    }
    case 'UNMOUNT_DIALOG': {
      draft[action.name] = {
        visible: false,
        props: draft[action.name]?.props,
        mounted: false,
      };
      draft.open_dialogs = draft.open_dialogs.filter((n) => n !== action.name);
    }
  }
}, {});
