import {Link} from 'react-router-dom';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import Icon from 'shared/components/Icon.js';
import {MANAGER_PATHS} from 'shared/constants.js';

import styles from './Integrations.module.scss';
import {ReactComponent as IntegrationsSvg} from './integrations.svg';

export default function Integrations({minimized}) {
  return (
    <Link
      className={classNames(styles.feedback_button, text_styles.body2_left)}
      to={MANAGER_PATHS.IntegrationsScreen}>
      {!minimized && <span>Integrationen</span>}
      <Icon className={minimized && styles.icon}>
        <IntegrationsSvg />
      </Icon>
    </Link>
  );
}
