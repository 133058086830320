import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';

import Layout from '../../components/layout/Layout.js';

import styles from './IntegrationsScreen.module.scss';
import EverrealIntegrationTile from './EverrealIntegrationTile.js';

export default function IntegrationsScreen() {
  return (
    <Layout>
      <div className={styles.container}>
        <h1 className={classNames(text_styles.h1_bold, styles.heading)}>
          Integrationen
        </h1>
        <div className={styles.grid}>
          <EverrealIntegrationTile />
        </div>
      </div>
    </Layout>
  );
}
