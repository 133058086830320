import Button from 'shared/components/Button.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import {closeDialog} from '../../effects.js';
import Dialog from '../../components/dialog/Dialog.js';
import IbanDisplay from '../../components/IbanDisplay.js';

import styles from './CashDepositSuccessDialog.module.scss';

export default function CashDepositSuccessDialog({viban, ...props}) {
  const footer = (
    <div className={styles.footer}>
      <Button
        title="Fertig"
        onClick={() => closeDialog('cash_deposit_success')}
      />
    </div>
  );

  return (
    <Dialog
      headerClassName={styles.dialog_header}
      contentClassName={styles.dialog_content}
      footer={footer}
      {...props}>
      <div className={styles.info_container}>
        <p
          className={classNames(
            text_styles.h1_bright_bold_left,
            styles.header,
          )}>
          Herzlichen Glückwunsch – <br />
          Sie können das Kautionskonto <br />
          ab jetzt verwenden.
        </p>
      </div>

      <div className={styles.content}>
        <p className={classNames(text_styles.body1_bold_left, styles.text)}>
          Bitte verwenden Sie das gerade erstellte Kautionskonto ausschließlich
          für die Kautionszahlungen von diesem Mietverhältnis.
        </p>

        <IbanDisplay iban={viban} />

        <p className={text_styles.caption_left}>
          Nach Zahlungseingang sehen Sie neben der festgelegten Kautionshöhe den
          erhalten Betrag.
        </p>
      </div>
    </Dialog>
  );
}
