import {Controller} from 'react-hook-form';
import DateInput from 'shared/components/DateInput.js';
import Input from 'shared/components/Input.js';
import PriceInput from 'shared/components/PriceInput.js';
import {centsToPrice} from 'shared/utils.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import {PROPERTY_TYPES} from 'shared/constants.js';

import {alert} from '../../effects.js';
import RadioToggle from '../../components/RadioToggle.js';

import styles from './RentalData.module.scss';

export default function RentalData({
  rental_contract,
  errors,
  register,
  watch,
  control,
}) {
  return (
    <div className={styles.wrapper}>
      <Controller
        control={control}
        name="property_type"
        render={({field: {onChange, name}}) => (
          <RadioToggle
            name={name}
            option_1={PROPERTY_TYPES.apartment}
            option_1_label="Wohnung"
            option_2={PROPERTY_TYPES.house}
            option_2_label="Haus"
            onChange={onChange}
            watch={watch}
          />
        )}
      />

      <Controller
        control={control}
        name="deposit_amount_cents"
        defaultValue={
          rental_contract.deposit_amount_cents
            ? centsToPrice(rental_contract.deposit_amount_cents)
            : ''
        }
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            label="Kaution (€)"
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <div className={styles.row}>
        <Controller
          control={control}
          name="cold_rent_cents"
          defaultValue={
            rental_contract.cold_rent_cents
              ? centsToPrice(rental_contract.cold_rent_cents)
              : ''
          }
          render={({field: {value, onChange, name}}) => (
            <PriceInput
              value={value}
              onChange={onChange}
              label="Kaltmiete (€)"
              error={errors[name]?.message}
              name={name}
            />
          )}
        />
        <Controller
          control={control}
          name="warm_rent_cents"
          defaultValue={
            rental_contract.warm_rent_cents
              ? centsToPrice(rental_contract.warm_rent_cents)
              : ''
          }
          render={({field: {value, onChange, name}}) => (
            <PriceInput
              value={value}
              onChange={onChange}
              label="Warmmiete (€)"
              error={errors[name]?.message}
              name={name}
            />
          )}
        />
      </div>

      <div className={styles.row}>
        <Input
          defaultValue={rental_contract.street_name || ''}
          label="Straße"
          error={errors.street_name?.message}
          {...register('street_name')}
        />
        <Input
          defaultValue={rental_contract.street_number || ''}
          label="Hausnummer"
          error={errors.street_number?.message}
          {...register('street_number')}
        />
      </div>
      {watch('property_type') === PROPERTY_TYPES.apartment && (
        <div className={styles.row}>
          <Input
            defaultValue={rental_contract?.postal_code || ''}
            type="text"
            label="Etage"
            placeholder="..."
            error={errors.storey?.message}
            {...register('storey')}
          />
        </div>
      )}
      <div className={styles.row}>
        <Input
          defaultValue={rental_contract?.postal_code || ''}
          type="text"
          label="PLZ"
          placeholder="..."
          error={errors.postal_code?.message}
          {...register('postal_code')}
        />
        <Input
          defaultValue={rental_contract.region || ''}
          label="Ort"
          error={errors.region?.message}
          {...register('region')}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.country_container}>
          <div className={classNames(text_styles.caption_left, styles.label)}>
            Land
          </div>
          <div
            className={styles.country_select}
            onClick={() => {
              alert({
                title:
                  'Wir bauen unseren Service aus, aktuell gilt dieser jedoch nur in Deutschland',
                text: 'Bitte senden Sie eine E-Mail an partnersupport@getmomo.de, falls Sie unseren Service auch in anderen Ländern nutzen möchten. Damit wir unser Angebot für Sie entsprechend optimieren können.',
              });
            }}>
            Deutschland
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <Controller
          name="signed_date"
          control={control}
          defaultValue={rental_contract.signed_date || null}
          render={({field: {value, onChange, name}}) => (
            <DateInput
              className={styles.column_2}
              value={value}
              onChange={onChange}
              label="Wann wurde der Mietvertrag unterschrieben"
              maxDate={new Date()}
              error={errors[name]?.message}
              name={name}
            />
          )}
        />
        <Controller
          name="start_date"
          control={control}
          defaultValue={rental_contract.start_date || null}
          render={({field: {value, onChange, name}}) => (
            <DateInput
              className={styles.column_2}
              value={value}
              onChange={onChange}
              label="Der im Mietvertrag vereinbarte Einzugstermin"
              error={errors[name]?.message}
              name={name}
            />
          )}
        />
      </div>
    </div>
  );
}
