import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import CircleSpinner from './CircleSpinner.js';
import styles from './Button.module.scss';

export default function Button({
  onClick,
  disabled = false,
  loading = false,
  title = '',
  secondary = false,
  inactive = false,
  icon,
  Icon,
  className,
  textClassName,
  ...props
}) {
  const child = loading ? (
    <CircleSpinner />
  ) : (
    <span
      className={classNames(
        secondary && (inactive || disabled) && styles.secondaryDisabled,
        secondary
          ? text_styles.button_bold_dark
          : text_styles.button_bold_bright,
        textClassName,
      )}>
      {title}
    </span>
  );

  return (
    <button
      className={classNames(
        styles.button,
        secondary && styles.secondary,
        (inactive || disabled) && styles.disabled,
        className,
      )}
      onClick={onClick}
      disabled={loading || disabled}
      {...props}>
      <div className={styles.container}>
        {icon && (
          <img
            src={icon}
            alt="icon"
            className={styles.image}
            height="24px"
            width="24px"
          />
        )}
        {Icon && <Icon alt="icon" className={styles.icon} />}
        {child}
      </div>
    </button>
  );
}
