import terms_and_conditions_pdf from 'shared/assets/termsheets/2023-04-25.Nutzungsbedingungen.Getmomo-Plattform.pdf';
import special_conditions_pdf from 'shared/assets/termsheets/2023-07-06.Besondere.Bedingungen.fur.die.Vermittlung.von.Mietkautionsburgschaften.pdf';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import styles from './TermsModal.module.scss';

export default function TermsModal() {
  return (
    <div>
      <p className={styles.information_text}>
        Die aktualisierten Dokumente stehen Ihnen unter dem folgenden Link für
        Sie zum Download bereit:
      </p>

      <div className={styles.link_container}>
        <a
          className={classNames(text_styles.body2, styles.link)}
          href={terms_and_conditions_pdf}
          target="_blank"
          rel="noreferrer">
          Allgemeine Geschäftsbedingungen
        </a>
        <a
          className={classNames(text_styles.body2, styles.link)}
          href={special_conditions_pdf}
          target="_blank"
          rel="noreferrer">
          Besonderen Bedingungen (Mietkaution)
        </a>
      </div>
    </div>
  );
}
