import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {NotifyProvider} from 'shared/NotifyProvider.js';

import store from './store/store.js';
import Router from './Router.js';
import {bootstrap} from './actions.js';
import {reportError} from './effects.js';

export default function App() {
  useEffect(() => {
    bootstrap().catch(reportError);
  }, []);

  return (
    <Provider store={store}>
      <NotifyProvider>
        <Router />
      </NotifyProvider>
    </Provider>
  );
}
