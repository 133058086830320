import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useHistory} from 'react-router-dom';
import classNames from 'classnames/bind';
import TextInput from 'shared/components/TextInput.js';
import {RPC} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import {handleError, alert, showGenericError} from '../../effects.js';
import Spinner from '../../components/spinner/Spinner.js';

import AuthenticationScreen from './AuthenticationScreen.js';
import styles from './ResetPasswordScreen.module.scss';

export default function ResetPasswordScreen() {
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState();

  const location = useLocation();
  const token = location.search.slice(1);

  useEffect(() => {
    setLoading(true);
    RPC('verifyResetPasswordToken', {token})
      .then(({valid}) => {
        setValid(valid);
        if (!valid) {
          alert({
            title: 'Der Link ist nicht mehr gültig',
            text: 'Aus Sicherheitsgründen wurde der Link deaktiviert, bitte beantragen Sie einen neuen Link.',
            label: null,
            removeBackdrop: true,
          });
        }
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [token]);

  return (
    <AuthenticationScreen>
      <p className={text_styles.h1_bright_bold_centered}>Getmomo Passwort</p>
      <div className={styles.card}>
        {valid && !loading ? (
          <ResetPassword token={token} />
        ) : (
          <Spinner className={styles.spinner} />
        )}
      </div>
    </AuthenticationScreen>
  );
}

function ResetPassword({token}) {
  const {
    register,
    formState: {isSubmitting, isValid},
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory();

  const onSubmit = handleSubmit(
    ({password}) =>
      RPC('resetPassword', {token, password})
        .then(() => {
          history.push('/');
        })
        .catch(handleError),
    (errors) => {
      if (errors.password_repeat) {
        return alert({
          title: 'Die Passwörter stimmen nicht überein',
          text: 'Bitte überprüfen Sie die Schreibweise oder kontaktieren Sie unseren Support.',
          label: 'Erneut versuchen',
          removeBackdrop: true,
        });
      }
      if (errors.password) {
        return alert({
          text: 'Bitte mindestens 8 Zeichen verwenden',
          removeBackdrop: true,
        });
      }
      showGenericError();
    },
  );

  return (
    <>
      <div className={styles.cardTop}>
        <p className={text_styles.body1_bold_centered}>
          Bitte legen Sie Ihr neues Passwort fest
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <TextInput
          type="password"
          placeholder="Passwort"
          autoComplete="new-password"
          className={classNames(styles.input, styles.input_password)}
          autoFocus={true}
          {...register('password', {required: true, minLength: 8})}
        />

        <TextInput
          type="password"
          placeholder="Passwort erneut eingeben"
          autoComplete="new-password"
          className={styles.input}
          {...register('password_repeat', {
            validate: (value) => value === watch('password'),
          })}
        />
        <div className={styles.button_container}>
          <Button
            type="submit"
            title="OK"
            loading={isSubmitting}
            inactive={!isValid}
            className={styles.button}
          />
        </div>
      </form>
    </>
  );
}
