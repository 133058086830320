import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import TextInput from 'shared/components/TextInput.js';
import {RPC} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';
import {useHistory, Link} from 'react-router-dom';
import terms_and_conditions_pdf from 'shared/assets/termsheets/2023-04-25.Nutzungsbedingungen.Getmomo-Plattform.pdf';
import special_conditions_pdf from 'shared/assets/termsheets/2023-07-06.Besondere.Bedingungen.fur.die.Vermittlung.von.Mietkautionsburgschaften.pdf';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min.js';
import {MANAGER_PATHS} from 'shared/constants.js';

import {alert, handleError, showGenericError} from '../../effects.js';
import Spinner from '../../components/spinner/Spinner.js';
import {login} from '../../actions.js';

import styles from './InvitationScreen.module.scss';
import AuthenticationScreen from './AuthenticationScreen.js';

export default function InvitationScreen() {
  const history = useHistory();
  const {search} = useLocation();
  const {deposit_id} = useParams();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [email_address, setEmailAddress] = useState('');
  const token = search.slice(1);

  const {
    register,
    formState: {isSubmitting, isValid},
    handleSubmit,
    watch,
  } = useForm({mode: 'onChange'});

  useEffect(() => {
    setLoading(true);
    RPC('verifyInvitationToken', {token})
      .then(({valid, email_address}) => {
        setValid(valid);
        if (!valid) {
          alert({
            title: 'Der Link ist nicht mehr gültig',
            text: 'Aus Sicherheitsgründen wurde der Link deaktiviert, bitte beantragen Sie einen neuen Link.',
            label: null,
            removeBackdrop: true,
          });
        }
        setEmailAddress(email_address);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [token, setEmailAddress]);

  const onSubmit = handleSubmit(
    ({password}) =>
      RPC('acceptInvitation', {password, token})
        .then(() => {
          login({email_address, password, history, deposit_id});
        })
        .catch(handleError),
    (errors) => {
      if (errors.password_repeat) {
        return alert({
          title: 'Die Passwörter stimmen nicht überein',
          text: 'Bitte überprüfen Sie die Schreibweise oder kontaktieren Sie unseren Support.',
          label: 'Erneut versuchen',
          removeBackdrop: true,
        });
      }
      if (errors.password) {
        return alert({
          text: 'Bitte mindestens 8 Zeichen verwenden',
          removeBackdrop: true,
        });
      }
      showGenericError();
    },
  );

  return (
    <AuthenticationScreen>
      <div className={styles.content}>
        <p className={text_styles.h1_bright_bold_centered}>
          Getmomo Nutzerkonto erstellen
        </p>

        <div className={styles.card}>
          {valid && !loading ? (
            <>
              <div className={styles.card_title}>
                <p className={text_styles.body1_bold_centered}>
                  Bitte erstellen Sie ein Passwort für {email_address}
                </p>
              </div>
              <form onSubmit={onSubmit}>
                <TextInput
                  type="password"
                  placeholder="Passwort"
                  autoComplete="new-password"
                  className={styles.input}
                  autoFocus={true}
                  {...register('password', {required: true, minLength: 8})}
                />

                <TextInput
                  type="password"
                  placeholder="Passwort erneut eingeben"
                  autoComplete="new-password"
                  className={styles.input}
                  {...register('password_repeat', {
                    validate: (value) => value === watch('password'),
                  })}
                />
                <div className={styles.button_container}>
                  <Button
                    type="submit"
                    title="Nutzerkonto erstellen"
                    loading={isSubmitting}
                    disabled={!isValid}
                    className={styles.button}
                  />
                </div>
                <div>
                  <p
                    className={classNames(
                      text_styles.caption_left,
                      styles.terms_text,
                    )}>
                    Indem Sie auf „Nutzerkonto erstellen“ klicken, akzeptieren
                    Sie unsere{' '}
                    <a
                      href={terms_and_conditions_pdf}
                      target="_blank"
                      rel="noreferrer">
                      Allgemeinen Geschäftsbedingungen
                    </a>{' '}
                    und gehen ein Vertragsverhältnis mit uns auf der Grundlage
                    der{' '}
                    <a
                      href={special_conditions_pdf}
                      target="_blank"
                      rel="noreferrer">
                      Besonderen Bedingungen für die Mietkautionsbürgschaft
                    </a>{' '}
                    ein.
                  </p>
                </div>
              </form>
            </>
          ) : (
            <Spinner className={styles.spinner} />
          )}
        </div>
        <Link
          to={{
            pathname: `${MANAGER_PATHS.DepositsScreen}/${deposit_id}`,
            search: `?${token}`,
          }}
          className={classNames(text_styles.button_bold_bright, styles.link)}>
          Ich habe bereits ein Nutzerkonto
        </Link>
      </div>
    </AuthenticationScreen>
  );
}
