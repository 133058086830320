import React from 'react';
import {Modal} from 'react-bootstrap';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import IconButton from 'shared/components/IconButton.js';
import {useSelector} from 'react-redux';

import {ReactComponent as CloseSvg} from './close.svg';
import styles from './Dialog.module.scss';

export default function Dialog({
  dialog_name,
  title,
  footer,
  children,
  additionalIcons = [], // Render props (function returning element)
  className,
  onHide,
  backButton,
  headerClassName,
  contentClassName,
  ...props
}) {
  const open_dialogs = useSelector((state) => {
    return state.dialogs.open_dialogs;
  });
  return (
    <Modal
      onHide={onHide}
      dialogClassName={classNames(styles.dialog, className)}
      contentClassName={styles.container}
      backdrop={dialog_name === open_dialogs[0] && 'static'}
      backdropClassName={styles.backdrop}
      {...props}>
      <div className={classNames(styles.header, headerClassName)}>
        {backButton}
        <p className={text_styles.body1_bold_left}>{title}</p>
        <div className={styles.header_icons}>
          {additionalIcons.map((AdditionalIcon, idx) => (
            <AdditionalIcon key={idx} />
          ))}
          {onHide && (
            <IconButton onClick={onHide} className={styles.close}>
              <CloseSvg />
            </IconButton>
          )}
        </div>
      </div>
      <div className={classNames(styles.content, contentClassName)}>
        {children}
      </div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </Modal>
  );
}
