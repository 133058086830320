import {useState} from 'react';

import AuthenticationScreen from './AuthenticationScreen.js';
import RequestResetPasswordForm from './RequestResetPasswordForm.js';
import ResetPasswordSent from './ResetPasswordSent.js';

export default function RequestResetPasswordScreen() {
  const [password_reset_sent, setPasswordResetSent] = useState(false);

  return (
    <AuthenticationScreen>
      {password_reset_sent ? (
        <ResetPasswordSent />
      ) : (
        <RequestResetPasswordForm setPasswordResetSent={setPasswordResetSent} />
      )}
    </AuthenticationScreen>
  );
}
