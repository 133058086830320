import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {
  formatDate,
  centsToPrice,
  isDepositGuaranteePending,
  openExternal,
  formatShortId,
} from 'shared/utils.js';
import Input from 'shared/components/Input.js';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {setFormErrors} from 'shared/effects.js';
import SeparatorLine from 'shared/components/SeparatorLine';
import TextArea from 'shared/components/TextArea.js';
import IconButton from 'shared/components/IconButton.js';
import Icon from 'shared/components/Icon.js';
import {useParams, useHistory} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';
import {MANAGER_PATHS} from 'shared/constants.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {updateRentalContract} from '../../actions.js';
import {isDeleteAllowed} from '../../lib/utils.js';
import {
  handleError,
  alert,
  deleteDepositGuarantee,
  confirmClose,
} from '../../effects.js';
import {ReactComponent as TrashSvg} from '../../assets/trash.svg';
import {ReactComponent as OpenNewWindowSvg} from '../../assets/open_in_new.svg';
import {ReactComponent as HelpSvg} from '../../assets/icons-help.svg';
import Spinner from '../../components/spinner/Spinner.js';

import styles from './DepositGuaranteeDialog.module.scss';

export default function DepositGuaranteeDialog({onHide, ...props}) {
  const [visible, setVisible] = useState(true);
  const {deposit_id} = useParams();
  const [deposit_guarantee, setDepositGuarantee] = useState(null);
  const [fetch_error, setFetchError] = useState(null);
  const {notify} = useNotify();

  useEffect(() => {
    RPC('getDepositGuarantee', {deposit_id})
      .then((deposit_guarantee) => {
        setDepositGuarantee(deposit_guarantee);
      })
      .catch((err) => {
        handleError(err);
        setFetchError(err);
      });
  }, [deposit_id]);

  const {
    register,
    reset,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const loading = !deposit_guarantee && !fetch_error;

  useEffect(() => {
    reset(deposit_guarantee);
  }, [reset, deposit_guarantee]);

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, deposit_guarantee, setError, setVisible, notify}),
  );

  const certificate_url = deposit_guarantee?.certificate_url;

  const footer = (
    <div className={styles.footer}>
      <div className={styles.status_row}>
        <div className={text_styles.body1_bold_left}>Status:</div>
        <div className={text_styles.body2}>{deposit_guarantee?.status}</div>
        <div>
          <a
            href="https://www.getmomo.de/haeufig-gestellte-fragen/status-mietkaution"
            target="_blank"
            rel="noreferrer">
            <Icon>
              <HelpSvg />
            </Icon>
          </a>
        </div>
      </div>
      <Button
        title="Speichern"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
      <Button
        title="Urkunde anzeigen"
        className={classNames(
          styles.margin_left,
          !certificate_url && styles.fake_disabled,
        )}
        onClick={() => {
          certificate_url
            ? openExternal(certificate_url)
            : alert({
                title: 'Sie können die Urkunde noch nicht herunterladen.',
                text: `Der Schutz wurde für den ${formatDate(
                  deposit_guarantee?.deposit_guarantee_start_date,
                )} beauftragt. Ab diesem Datum können Sie die Urkunde herunterladen. Wir informieren Sie per E-Mail.`,
              });
        }}
        disabled={isCertificateButtonDisabled(deposit_guarantee)}
      />
    </div>
  );

  function TrashElement() {
    return (
      <IconButton
        disabled={!isDeleteAllowed(deposit_guarantee)}
        onClick={() =>
          deleteDepositGuarantee({deposit_guarantee, notify}).then(
            (didDelete) => {
              if (didDelete) setVisible(false);
            },
          )
        }>
        <TrashSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Vertragsdaten"
      show={visible}
      footer={footer}
      additionalIcons={deposit_guarantee?.id && [TrashElement]}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      {loading && <Spinner className={styles.spinner} />}
      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Pflichtangaben:
      </div>
      <div className={styles.row}>
        <Input
          value={deposit_guarantee?.tenant_first_name || ''}
          label="Vorname"
          readOnly
        />

        <Input
          value={deposit_guarantee?.tenant_last_name || ''}
          label="Nachname"
          readOnly
        />
      </div>

      <Input
        value={deposit_guarantee?.tenant_phone_number || ''}
        label="Telefonnummer"
        readOnly
      />

      <SeparatorLine />

      <div className={styles.row}>
        <Input
          value={
            deposit_guarantee?.street_name
              ? `${deposit_guarantee?.street_name} ${deposit_guarantee?.street_number}`
              : ''
          }
          label="Straße & Hausnummer"
          readOnly
        />
        <Input
          value={deposit_guarantee?.postal_code || ''}
          label="PLZ"
          readOnly
        />
      </div>

      <div className={styles.row}>
        <Input value={deposit_guarantee?.region || ''} label="Ort" readOnly />
        <Input value="Deutschland" label="Land" readOnly />
      </div>

      <SeparatorLine />

      <div className={styles.row}>
        <Input
          value={
            deposit_guarantee?.cold_rent_cents
              ? centsToPrice(deposit_guarantee.cold_rent_cents)
              : ''
          }
          label="Kaltmiete"
          readOnly
        />
        <Input
          value={
            deposit_guarantee?.deposit_amount_cents
              ? centsToPrice(deposit_guarantee.deposit_amount_cents)
              : ''
          }
          label="Kaution"
          readOnly
        />
      </div>

      <div className={styles.row}>
        <Input
          value={
            deposit_guarantee?.signed_date
              ? formatDate(deposit_guarantee.signed_date)
              : ''
          }
          label="Wann wurde der Mietvertrag unterschrieben"
          readOnly
        />
        <Input
          value={
            deposit_guarantee?.start_date
              ? formatDate(deposit_guarantee.start_date)
              : ''
          }
          label="Der im Mietvertrag vereinbarte Einzugstermin"
          readOnly
        />
      </div>

      <div className={styles.row}>
        <PropertyOwner property_owner={deposit_guarantee?.property_owner} />
      </div>

      <SeparatorLine />

      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Optionale Angaben:
      </div>

      <Input
        defaultValue={deposit_guarantee?.given_reference || ''}
        label="Beschreibung Wohneinheit "
        className={styles.margin_right}
        error={errors.given_reference?.message}
        {...register('given_reference')}
      />

      <TextArea
        defaultValue={deposit_guarantee?.notes || ''}
        label="Notizen"
        placeholder="…"
        maxLength="500"
        error={errors.notes?.message}
        {...register('notes')}
      />

      <div className={styles.details}>
        <div className={text_styles.caption_left}>
          Erstellt am{' '}
          {deposit_guarantee?.created_at
            ? formatDate(deposit_guarantee.created_at)
            : ''}
        </div>
        <div className={text_styles.caption_left} data-testid="short_id">
          Momo-ID: {formatShortId(deposit_guarantee?.short_id)}
        </div>
      </div>

      {isDepositGuaranteePending(deposit_guarantee) && (
        <div className={classNames(text_styles.body1_bold_left)}>
          Wir informieren Sie sobald der Mieter alles ausgefüllt hat und der
          Mietkautionsschutz aktiv ist.
        </div>
      )}
    </Dialog>
  );
}

function PropertyOwner({property_owner}) {
  const history = useHistory();

  if (!property_owner) {
    return <Input value="" label="Eigentümer" readOnly />;
  }

  if (property_owner.is_manager) {
    return (
      <Input value={property_owner.display_name} label="Eigentümer" readOnly />
    );
  }

  return (
    <div>
      <div className={text_styles.caption_left}>Eigentümer</div>
      <button
        onClick={() =>
          history.push(
            `${MANAGER_PATHS.PropertyOwnersScreen}/${property_owner.id}`,
          )
        }
        className={classNames(text_styles.body2, styles.text_button)}>
        <>
          <Icon className={styles.icon}>
            <OpenNewWindowSvg />
          </Icon>
          {property_owner.display_name}
        </>
      </button>
    </div>
  );
}

async function proceed({
  fields,
  deposit_guarantee,
  setError,
  setVisible,
  notify,
}) {
  const {rental_contract_id} = deposit_guarantee;

  try {
    await updateRentalContract({
      id: rental_contract_id,
      given_reference: fields.given_reference,
      notes: fields.notes,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      } else if (err.message) {
        alert({title: err.message});
      } else {
        alert({
          title: 'Die Pflichtangaben sind unvollständig!',
          text: 'Bitte tragen Sie alle Daten unter „Pflichtangaben“ ein. Sollten Ihnen verbindliche Angaben fehlen, können Sie den Vorgang abbrechen und den „Neuen Mieter“ später hinzufügen.',
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  notify({text: 'Die Änderungen wurden gespeichert'});
  setVisible(false);
}

export function isCertificateButtonDisabled(deposit_guarantee) {
  return (
    deposit_guarantee?.disabled_at !== null ||
    deposit_guarantee?.deposit_guarantee_rejected_at !== null ||
    deposit_guarantee?.deposit_guarantee_accepted_at === null
  );
}
