import {useState} from 'react';

//steps should have object with id property
//steps = [{id : 'step_name1'}, {id: 'step_name2'}]
export function useWizard(steps = []) {
  const [active_index, setActiveIndex] = useState(0);

  function next() {
    setActiveIndex((index) => index + 1);
  }

  function previous() {
    setActiveIndex((index) => index - 1);
  }

  const step = steps[active_index];

  const index_step = active_index + 1;
  const is_last_step = index_step === steps.length;
  const is_first_step = index_step === 1;

  return {step, next, previous, index_step, is_last_step, is_first_step};
}
