import React from 'react';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import styles from './Pagination.module.scss';

export default function Pagination({total, offset, setOffset, per_page}) {
  const num_pages = Math.ceil(total / per_page);
  const current_page = Math.floor(offset / per_page);
  const {
    pages,
    is_first_page,
    is_last_page,
    previous_page,
    next_page,
    current_page_display,
    changeToPage,
  } = getPaginationState({
    per_page,
    num_pages,
    current_page,
    setOffset,
    max_num_display: 4,
  });

  return (
    <div className={styles.container}>
      <div className={classNames(text_styles.typeface, styles.text)}>
        <span>
          Seite {current_page_display} von {num_pages}
        </span>
      </div>
      {num_pages > 1 && (
        <div>
          <button
            className={classNames(
              text_styles.typeface,
              styles.text_button,
              styles.text_button__left,
              is_first_page && styles.disabled,
            )}
            disabled={is_first_page}
            onClick={() => changeToPage(previous_page)}>
            Vorherige Seite
          </button>

          {pages.map((page, index) => {
            return (
              <button
                key={index}
                className={classNames(
                  text_styles.typeface,
                  styles.button,
                  page.is_active && styles.active,
                  page.is_first_button && styles.button__left,
                  page.is_last_button && styles.button__right,
                )}
                onClick={() => changeToPage(page.value)}>
                {page.display_value}
              </button>
            );
          })}

          <button
            className={classNames(
              text_styles.typeface,
              styles.text_button,
              styles.text_button__right,
              is_last_page && styles.disabled,
            )}
            disabled={is_last_page}
            onClick={() => changeToPage(next_page)}>
            Nächste Seite
          </button>
        </div>
      )}
    </div>
  );
}

function getPaginationState({
  per_page,
  num_pages,
  current_page,
  setOffset,
  max_num_display = 4,
}) {
  if (num_pages < max_num_display) max_num_display = num_pages;
  const arr = getDisplayedPageValues(num_pages, current_page, max_num_display);

  const pages = arr.map((page_number, index) =>
    createPageItem({
      page_number,
      index,
      current_page,
      button_count: arr.length,
    }),
  );
  const is_first_page = current_page === 0;
  const is_last_page = current_page === num_pages - 1;
  const previous_page = current_page - 1;
  const next_page = current_page + 1;
  const current_page_display = current_page + 1;

  function changeToPage(page_number) {
    const offset = per_page * page_number;
    setOffset(offset);
  }

  return {
    pages,
    is_first_page,
    is_last_page,
    previous_page,
    next_page,
    current_page_display,
    changeToPage,
  };
}

function getDisplayedPageValues(num_pages, current_page, max_num_display) {
  const page_array = Array.from({length: num_pages}, (_, i) => i);

  if (current_page === 0) {
    return page_array.slice(current_page, current_page + max_num_display);
  } else if (current_page >= num_pages - (max_num_display - 1)) {
    return page_array.slice(num_pages - max_num_display, num_pages);
  }
  return page_array.slice(
    current_page - 1,
    current_page + (max_num_display - 1),
  );
}

function createPageItem({page_number, index, current_page, button_count}) {
  return {
    value: page_number,
    is_active: current_page === page_number,
    display_value: page_number + 1,
    is_first_button: index === 0,
    is_last_button: index === button_count - 1,
  };
}
