export function formatIban(iban) {
  // eslint-disable-next-line unicorn/prefer-default-parameters
  let remainder = iban || '';
  const parts = [];
  while (remainder.length > 0) {
    parts.push(remainder.slice(0, 4));
    remainder = remainder.slice(4);
  }
  return parts.join(' ');
}
