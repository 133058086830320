import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import RadioButton from 'shared/components/RadioButton';
import {Controller} from 'react-hook-form';

import verified from './icon-verified.png';
import styles from './ChecklistItem.module.scss';

export default function ChecklistItem({control, label, name, watch}) {
  const value = watch(name);

  return (
    <div className={styles.spaced_row}>
      <div>
        <label className={text_styles.body1_left}>{label}</label>
        <div className={styles.radio_group}>
          <Controller
            control={control}
            name={name}
            render={({field: {onChange}}) => (
              <>
                <RadioButton
                  value="true"
                  checked={value === true}
                  label="Ja"
                  name={name}
                  onChange={(e) => onChange(e.target.value === 'true')}
                />
                <RadioButton
                  value="false"
                  checked={value === false}
                  label="Nein"
                  name={name}
                  onChange={(e) => onChange(e.target.value === 'true')}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className={styles.circle}>
        {typeof value === 'boolean' ? (
          <img
            src={verified}
            alt="Verifiziert"
            className={classNames(
              styles.verified,
              isValidResponse(value, name) ? styles.success : styles.alert,
            )}
            height="32px"
            width="32px"
          />
        ) : (
          <img
            src={verified}
            alt="Verifiziert"
            className={styles.verified}
            height="32px"
            width="32px"
          />
        )}
      </div>
    </div>
  );
}

export function isValidResponse(value, name) {
  const should_be_true =
    name === 'statement_given' || name === 'flat_handed_over';
  const should_be_false =
    name === 'open_debt' ||
    name === 'open_litigation' ||
    name === 'open_obligations';

  if (!!value && should_be_true) return true;
  if (!value && should_be_false) return true;

  return false;
}
