import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import Input from 'shared/components/Input.js';
import {setFormErrors} from 'shared/effects.js';
import {useHistory} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';
import {MANAGER_PATHS, RENTAL_CONTRACT_ORIGIN} from 'shared/constants.js';

import {closeDialog, handleError} from '../effects.js';

import RadioToggle from './RadioToggle.js';
import styles from './CreateRentalContractDialog.module.scss';

export default function CreateRentalContractDialog({
  onSave,
  show_type_toggle = false,
  ...props
}) {
  const history = useHistory();

  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {type: 'individual'},
  });

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, onSave, setError, history}),
  );

  const type = watch('type');
  const company_selected = type === 'company';
  const individual_selected = type === 'individual';

  const footer = (
    <div className={styles.footer}>
      <Button
        type="submit"
        title="Mieter erstellen"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </div>
  );

  return (
    <Dialog
      title="Mieter erstellen"
      footer={footer}
      onExited={() => history.push(MANAGER_PATHS.DepositsScreen)}
      {...props}>
      <div className={styles.row}>
        {show_type_toggle && (
          <>
            <Controller
              control={control}
              name="type"
              render={({field: {onChange, name}}) => (
                <RadioToggle
                  name={name}
                  option_1="individual"
                  option_1_label="Privatperson"
                  option_2="company"
                  option_2_label="Firma"
                  onChange={onChange}
                  watch={watch}
                />
              )}
            />
          </>
        )}
      </div>
      {company_selected && (
        <div className={styles.row}>
          <Input
            placeholder="z. B. Schmitt Immobilien GmbH"
            label="Firma und Rechtsform"
            error={errors.company_name?.message}
            {...register('company_name')}
          />
        </div>
      )}

      {individual_selected && (
        <div className={styles.row}>
          <Input
            label="Vorname"
            className={styles.tenant_first_name}
            error={errors.tenant_first_name?.message}
            {...register('tenant_first_name')}
          />
          <Input
            label="Nachname"
            error={errors.tenant_last_name?.message}
            {...register('tenant_last_name')}
          />
        </div>
      )}
    </Dialog>
  );
}

async function proceed({fields, onSave, setError, setVisible}) {
  let rental_contract;

  try {
    rental_contract = await RPC('createRentalContract', {
      ...fields,
      origin: RENTAL_CONTRACT_ORIGIN.manager,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  if (onSave) {
    await onSave(rental_contract);
    closeDialog('create_rental_contract');
    return;
  }
  setVisible(false);
}
