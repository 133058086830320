import countries from 'world_countries_lists/data/countries/de/countries.json';
import {DEPOSIT_GUARANTEE_REASONS} from 'shared/constants.js';

export function translatePermissionReason(reason) {
  switch (reason) {
    case DEPOSIT_GUARANTEE_REASONS.contract_running: {
      return 'The contract is running.';
    }
    case DEPOSIT_GUARANTEE_REASONS.contract_released: {
      return 'The contract is released.';
    }
    case DEPOSIT_GUARANTEE_REASONS.contract_deleted: {
      return 'The contract is deleted.';
    }
    case DEPOSIT_GUARANTEE_REASONS.application_submitted: {
      return 'The application is submitted.';
    }
    default: {
      return null;
    }
  }
}

export function isClaimAllowed(deposit) {
  return deposit?.id && deposit.permissions.claim.length === 0;
}

export function isReleaseAllowed(deposit) {
  return deposit?.id && deposit.permissions.release.length === 0;
}

export function isDeleteAllowed(deposit) {
  return deposit?.id && deposit.permissions.delete.length === 0;
}

export function isCertificateAllowed(deposit) {
  return deposit?.id && deposit.permissions.certificate.length === 0;
}

export const country_select_options = countries.map((country) => {
  return {
    id: country.alpha2.toUpperCase(),
    name: country.name,
  };
});

export const gender_select_options = [
  {id: 'female', name: 'weiblich'},
  {id: 'male', name: 'männlich'},
];

export function getGermanGender(gender) {
  return gender === 'female' ? 'Weiblich' : 'Männlich';
}

export function isProduction() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'https://api.getmomo.app/manager/';
}

export function isSandbox() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'https://api.getmomo-sandbox.app/manager/';
}

export function isStaging() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'https://api.getmomo-staging.app/manager/';
}

export function isDevelopment() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'http://localhost:20305/manager/';
}

export function isTest() {
  const {NODE_ENV} = process.env;
  return NODE_ENV === 'test';
}
