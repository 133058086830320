import {useState, useEffect} from 'react';
import classNames from 'classnames';

import {ReactComponent as MomoLogoSmall} from './logo_mini.svg';
import styles from './Spinner.module.scss';

export default function Spinner({className, delay = 500}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const intervalID = setInterval(() => setShow(true), delay);
    return () => {
      clearInterval(intervalID);
    };
  }, [delay]);

  if (!show) return null;

  return (
    <div className={classNames(styles.container, className)}>
      <MomoLogoSmall alt="Wird geladen" className={styles.logo} />
      <div className={styles.loading} />
    </div>
  );
}
