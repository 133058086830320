import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'LOGGED_IN': {
      return {...draft, ...action.payload, status: 'logged_in'};
    }
    case 'BOOTSTRAPPED': {
      draft.account = action.payload;
      draft.status = action.payload ? 'logged_in' : 'logged_out';
      break;
    }
    case 'LOGGED_OUT': {
      draft.account = null;
      draft.status = 'logged_out';
      break;
    }
  }
}, {});
