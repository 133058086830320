import classnames from 'classnames';

import styles from './CircleSpinner.module.scss';

export default function CircleSpinner({
  className,
  classNameOutline,
  classNameSpinner,
}) {
  return (
    <svg className={classnames(styles.svg, className)} viewBox="0 0 32 32">
      <circle
        className={classnames(styles.outline, classNameOutline)}
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="4"
      />
      <circle
        className={classnames(styles.spinner, classNameSpinner)}
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="4"
      />
    </svg>
  );
}
