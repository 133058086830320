import {useMemo, useCallback, useContext, useState, createContext} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';

const NotifyContext = createContext(null);

export function useNotify() {
  return useContext(NotifyContext);
}

export function NotifyProvider({children}) {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');

  const notify = useCallback(({text}) => {
    setShow(true);
    setText(text);
  }, []);

  const notify_context = useMemo(
    () => ({
      notify,
    }),
    [notify],
  );

  return (
    <NotifyContext.Provider value={notify_context}>
      {children}
      <ToastContainer position="bottom-center">
        <Toast show={show} onClose={() => setShow(false)} autohide>
          <Toast.Body>{text}</Toast.Body>
        </Toast>
      </ToastContainer>
    </NotifyContext.Provider>
  );
}
