import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';

import styles from './IntegrationTile.module.scss';

export default function IntegrationTile({icon, title, children}) {
  return (
    <div className={styles.tile}>
      <div>{icon}</div>
      <h3 className={classNames(text_styles.body1_bold_left, styles.title)}>
        {title}
      </h3>
      <div>{children}</div>
    </div>
  );
}
