import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'RENTAL_CONTRACT_ADDED':
    case 'RENTAL_CONTRACT_DELETED':
    case 'RENTAL_CONTRACT_UPDATED':
    case 'TENANT_INVITED':
    case 'CASH_DEPOSIT_CREATED':
    case 'RENTAL_CONTRACT_CLAIM_CREATED': {
      draft.refresh++;
      break;
    }
  }
}, {});
