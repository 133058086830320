import classNames from 'classnames';
import {forwardRef} from 'react';

import Icon from './Icon.js';
import styles from './IconButton.module.scss';

// This component should only be used with SVG children
function IconButton({onClick, className, disabled, children}, forwardedRef) {
  return (
    <div
      className={classNames(styles.button, disabled && styles.disabled)}
      onClick={onClick}
      ref={forwardedRef}>
      <Icon className={className}>{children}</Icon>
    </div>
  );
}

export default forwardRef(IconButton);
