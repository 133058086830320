import React from 'react';
import {useTable} from 'react-table';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import Spinner from '../../components/spinner/Spinner.js';

import {columns} from './PropertyOwnersTable.js';
import table_styles from './PropertyOwnersTable.module.scss';
import styles from './PropertyOwnersPlaceholder.module.scss';

export default function PropertyOwnersPlaceholder({search_query, loading}) {
  const {getTableProps, headerGroups, getTableBodyProps} = useTable({
    columns,
    data: [],
  });
  const placeholder_rows = Array.from({length: 3}, (_, i) => i);

  return (
    <>
      <table className={table_styles.table} {...getTableProps()}>
        <thead className={table_styles.thead}>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                if (column.id === 'actions') return null;

                return (
                  <th
                    key={i}
                    className={classNames(
                      table_styles.th,
                      table_styles[column.id],
                    )}
                    {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {placeholder_rows.map((i) => {
            return (
              <tr
                key={i}
                className={classNames(
                  table_styles.tr,
                  table_styles[`placeholder_row__${i}`],
                )}>
                {columns.map((column, i) => {
                  if (column.id === 'actions') return null;

                  return (
                    <td key={i}>
                      <div className={styles.empty}>
                        <div className={table_styles[column.id]} />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className={classNames(text_styles.body2_centered, table_styles.text)}>
        {getPlaceholderText({search_query, loading})}
      </div>
      {loading && <Spinner className={styles.spinner} />}
    </>
  );
}

const PLACEHOLDER_TEXT_NONE_CREATED = `Sie haben noch keine Eigentümer angelegt.
Sie können Eigentümer per Klick auf „Eigentümer erstellen“ anlegen.`;

const PLACEHOLDER_TEXT_NO_MATCHES = `Wir haben kein Eigentümer gefunden.
Sie können einen Eigentümer per Klick auf „Eigentümer erstellen“ anlegen.`;

const PLACEHOLDER_TEXT_LOADING =
  'Einen Moment Bitte, wir durchsuchen Eintrag...';

function getPlaceholderText({search_query, loading}) {
  if (loading) {
    return PLACEHOLDER_TEXT_LOADING;
  }
  if (search_query.length > 0) {
    return PLACEHOLDER_TEXT_NO_MATCHES;
  }
  return PLACEHOLDER_TEXT_NONE_CREATED;
}
