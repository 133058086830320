import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import IconButton from '../IconButton.js';

import {ReactComponent as CloseSvg} from './close.svg';
import styles from './Dialog.module.scss';

export default function Dialog({
  dialog_name,
  title,
  footer,
  children,
  additionalIcons = [], // Render props (function returning element)
  className,
  onHide,
  onExited,
  backButton,
  show,
  ...props
}) {
  const [visible, setVisible] = useState(show ?? true);

  useEffect(() => {
    setVisible(show ?? true);
  }, [show]);

  async function close() {
    if (onHide && (await onHide()) === false) return;
    setVisible(false);
  }

  return (
    <Modal
      show={visible}
      onHide={close}
      onExited={onExited}
      dialogClassName={classNames(styles.dialog, className)}
      contentClassName={styles.container}
      backdropClassName={styles.backdrop}
      {...props}>
      <div className={styles.header}>
        {backButton}
        <div className={text_styles.body1_bold_left}>{title}</div>
        <div className={styles.header_icons}>
          {additionalIcons.map((icon, idx) => (
            <div key={idx} className={styles.icon_container}>
              {icon}
            </div>
          ))}
          {close && (
            <IconButton className={styles.close} onClick={close}>
              <CloseSvg />
            </IconButton>
          )}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </Modal>
  );
}
