import React, {useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import classNames from 'classnames';
import PriceInput from 'shared/components/PriceInput.js';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {setFormErrors} from 'shared/effects.js';
import TextArea from 'shared/components/TextArea.js';
import {useNotify} from 'shared/NotifyProvider.js';

import Dialog from '../../components/dialog/Dialog.js';
import {releaseDepositGuarantee} from '../../actions.js';
import {handleError, closeDialog} from '../../effects.js';

import Footer from './Footer.js';
import ChecklistItem from './ChecklistItem.js';
import styles from './ReleaseDialog.module.scss';
import FlatConditionBar from './FlatConditionBar.js';

export default function ReleaseDialog({deposit, ...props}) {
  const {notify} = useNotify();
  const {
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    register,
    watch,
    control,
    reset,
    setError,
  } = useForm({mode: 'onChange'});

  useEffect(() => {
    return RPC('getReleaseAnswers', {
      deposit_id: deposit.id,
    })
      .then((release_draft) => {
        // When invoking reset({ value }) without supplying defaultValues via useForm,
        // the library will replace defaultValues with a shallow clone value object which you provide (not deepClone).
        // https://react-hook-form.com/v6/api#reset
        reset(release_draft);
      })
      .catch(handleError);
  }, [deposit, reset]);

  const onRelease = handleSubmit((fields) =>
    release({deposit, fields, setError, notify}),
  );
  const onSave = handleSubmit((fields) =>
    save({deposit, fields, setError, notify}),
  );

  return (
    <Dialog
      title="Freigabe-Checkliste:"
      footer={
        <Footer
          isSubmitting={isSubmitting}
          isDirty={isDirty}
          onRelease={onRelease}
          onSave={onSave}
          watch={watch}
        />
      }
      onHide={() => closeDialog('release')}
      {...props}>
      <form>
        <hr className={styles.line} />
        <ChecklistItem
          control={control}
          watch={watch}
          name="open_debt"
          label="Gibt es noch offene Mietschulden?"
        />

        {watch('open_debt') && (
          <>
            <label className={classNames(text_styles.body1_left, styles.title)}>
              Wie hoch ist der offene Betrag &#40;€&#41;?
            </label>
            <Controller
              control={control}
              name="open_debt_amount_cents"
              render={({field: {value, onChange, name}}) => (
                <PriceInput
                  value={value}
                  onChange={onChange}
                  className={styles.column}
                  error={errors[name]?.message}
                />
              )}
            />
          </>
        )}
        <hr className={styles.line} />
        <ChecklistItem
          control={control}
          watch={watch}
          name="open_litigation"
          label="Läuft aktuell ein Rechtsstreit?"
        />
        <hr className={styles.line} />
        <ChecklistItem
          control={control}
          watch={watch}
          name="open_obligations"
          label="Gibt es weitere Verpflichtungen vom Mieter?"
        />
        <hr className={styles.line} />
        <ChecklistItem
          control={control}
          watch={watch}
          name="statement_given"
          label="Ist die abschließende Betriebskostenabrechnung erfolgt?"
        />
        <hr className={styles.line} />
        <ChecklistItem
          control={control}
          watch={watch}
          name="flat_handed_over"
          label="Ist die Abnahme der Wohnung erfolgt?"
        />
        {watch('flat_handed_over') && <FlatConditionBar register={register} />}
        <hr className={styles.line} />
        <label
          className={classNames(text_styles.body1_bold_left, styles.title)}>
          Notizen zum Vorgang (optional)
        </label>
        <TextArea
          placeholder="Hier können Notizen eingetragen werden …"
          rows="5"
          maxLength="500"
          error={errors.note?.message}
          {...register('note')}
        />
      </form>
    </Dialog>
  );
}

async function release({deposit, fields, setError, notify}) {
  const {open_debt_amount_cents, ...params} = fields;

  try {
    await RPC('saveReleaseAnswers', {
      deposit_id: deposit.id,
      ...params,
    });
    await releaseDepositGuarantee(deposit.id);
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  notify({text: 'Die Kaution wurde erfolgreich freigegeben.'});
  closeDialog('release');
}

async function save({deposit, fields, setError, notify}) {
  const {open_debt_amount_cents, ...params} = fields;

  try {
    await RPC('saveReleaseAnswers', {
      deposit_id: deposit.id,
      ...params,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  notify({text: 'Die Freigabe-Checkliste wurde erfolgreich gespeichert.'});
  closeDialog('release');
}
