export function isDepositGuaranteePending(rental_contract) {
  const is_active =
    rental_contract?.deposit_guarantee_accepted_at !== null &&
    new Date(rental_contract?.deposit_guarantee_start_date) <= new Date();

  return (
    !is_active &&
    !(
      rental_contract?.disabled_at ||
      rental_contract?.deposit_guarantee_rejected_at
    )
  );
}
