import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'PROPERTY_OWNER_ADDED':
    case 'PROPERTY_OWNER_DELETED':
    case 'PROPERTY_OWNER_UPDATED': {
      draft.refresh++;
      break;
    }
  }
}, {});
