import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Switch, Route, useHistory} from 'react-router-dom';
import {MANAGER_PATHS} from 'shared/constants.js';

import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import {showDialog, confirm, handleError} from '../../effects.js';
import {deletePropertyOwner} from '../../actions.js';
import Pagination from '../../components/Pagination.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import {useFetchRefresh} from '../../hooks.js';

import Placeholder from './PropertyOwnersPlaceholder.js';
import PropertyOwnersTable from './PropertyOwnersTable.js';
import styles from './PropertyOwnersScreen.module.scss';
import PropertyOwnerDialog from './PropertyOwnerDialog.js';
import CreatePropertyOwnerDialog from './CreatePropertyOwnerDialog.js';

const per_page = 10;
const default_sort_column = 'created_at';
const default_sort_direction = 'DESC';

export default function PropertyOwnersScreen() {
  const {refresh} = useSelector((state) => state.property_owners);
  const [search_query, setSearchQuery] = useState('');
  const [sort_column, setSortColumn] = useState(default_sort_column);
  const [sort_direction, setSortDirection] = useState(default_sort_direction);

  const {loading, rows, total, offset, setOffset} = useFetchRefresh({
    method: 'getPropertyOwners',
    search_query,
    per_page,
    refresh,
    sort_column,
    sort_direction,
  });

  function orderBy(field) {
    if (sort_column === field) {
      sort_direction === 'DESC'
        ? setSortDirection('ASC')
        : setSortDirection('DESC');
    } else {
      setSortColumn(field);
      setSortDirection('ASC');
    }
  }

  function resetTable() {
    setSortColumn(default_sort_column);
    setSortDirection(default_sort_direction);
    setOffset(0);
  }

  const placeholder = rows.length === 0 && (
    <Placeholder search_query={search_query} loading={loading} />
  );

  return (
    <Layout
      button={<ButtonCreatePropertyOwner resetTable={resetTable} />}
      search={<SearchInput onValue={setSearchQuery} />}>
      <div className={styles.content}>
        <Switch>
          <Route path={MANAGER_PATHS.CreatePropertyOwnerDialog}>
            <CreatePropertyOwnerDialog />
          </Route>
          <Route path={`${MANAGER_PATHS.PropertyOwnersScreen}/:id`}>
            <PropertyOwnerDialog
              onDeletePropertyOwner={onDeletePropertyOwner}
            />
          </Route>
        </Switch>
        {placeholder || (
          <PropertyOwnersTable
            onOpen={onOpenPropertyOwner}
            property_owners={rows}
            orderBy={orderBy}
            sort_column={sort_column}
            sort_direction={sort_direction}
          />
        )}
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={per_page}
          />
        )}
      </div>
    </Layout>
  );
}

function ButtonCreatePropertyOwner({resetTable}) {
  const history = useHistory();

  return (
    <ButtonAdd
      title="Eigentümer erstellen"
      onClick={() => {
        resetTable();
        history.push(MANAGER_PATHS.CreatePropertyOwnerDialog);
      }}
    />
  );
}

function onOpenPropertyOwner(property_owner, history) {
  if (property_owner?.is_manager) {
    showDialog('property_owner_read_only', {property_owner});
  } else {
    history.push(`${MANAGER_PATHS.PropertyOwnersScreen}/${property_owner.id}`);
  }
}

async function onDeletePropertyOwner({property_owner, notify}) {
  const {id} = property_owner;

  if (
    !(await confirm({
      title: `Möchten Sie den Eigentümer wirklich endgültig entfernen?`,
      text: 'Der Vorgang kann nicht Rückgängig gemacht werden, Sie können den Eigentümer aber jederzeit erneut anlegen.',
      proceed_label: 'Jetzt löschen',
    }))
  ) {
    return;
  }

  try {
    await deletePropertyOwner(id);
  } catch (err) {
    handleError(err);
    return;
  }

  notify({text: 'Der Vertrag wurde gelöscht.'});
}
