import {toISODate} from './toIsoDate.js';

// We communicate and store contract dates relative to the contract itself
// instead of relative to the time offset (timezone or dst)
// so if a German rental contract starts on the 5th of November 2021
// it is sent and stored as "2021-11-05" - not "2021-11-04T23:00:00.000Z"
export function toLocaleDate(date) {
  if (date === undefined || date === null) return date;
  return toISODate(date);
}
