import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';
import {RPC} from 'shared/api.js';
import classNames from 'classnames';
import {centsToPrice, formatDate, formatPrice} from 'shared/utils.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Button from 'shared/components/Button.js';

import {
  confirmClose,
  handleError,
  openCashDepositTransactionsReport,
} from '../../effects.js';

import styles from './CashDepositTransactionsDialog.module.scss';

export default function CashDepositTransactionsDialog({onHide, ...props}) {
  const [visible, setVisible] = useState(true);
  const {deposit_id} = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    RPC('getCashDepositTransactions', {deposit_id})
      .then(setData)
      .catch((err) => {
        handleError(err);
      });
  }, [deposit_id]);

  function onClose() {
    confirmClose(true);
    setVisible(false);
  }

  if (!data) return null;

  const {transactions, account_balance_cents, tenant_display_name, viban} =
    data;

  const footer = (
    <div className={styles.footer}>
      <p className={styles.sum}>
        <span className={styles.text}>Summe: </span>
        <span className={styles.sum_amount}>
          {formatPrice(account_balance_cents || 0)}
        </span>
      </p>
      <Button
        title="PDF"
        onClick={() => openCashDepositTransactionsReport(deposit_id)}
      />
    </div>
  );

  return (
    <Dialog
      title="Transaktionen"
      show={visible}
      onHide={onClose}
      footer={footer}
      {...props}>
      <div className={styles.container}>
        <div className={styles.account_owners_section}>
          <p className={text_styles.caption_left}>
            Virtuelles Unterkonto: {viban} | {tenant_display_name}
          </p>
        </div>
        <div className={classNames(styles.table, text_styles.caption_left)}>
          <div className={styles.table_header}>
            <div className={styles.date}>Datum</div>
            <div className={styles.sender_receiver}>Sender / Empfänger</div>
            <div className={styles.remittance_information}>
              Verwendungszweck
            </div>
            <div className={styles.amount}>Betrag (€)</div>
          </div>
          <div className={styles.table_body}>
            {transactions.map(
              (
                {
                  date,
                  counterparty_name,
                  counterparty_iban,
                  remittance_information,
                  amount,
                },
                i,
              ) => {
                return (
                  <div className={styles.table_row} key={i}>
                    <div className={styles.date}>{formatDate(date)}</div>
                    <div className={styles.sender_receiver}>
                      <p className={styles.counterparty_name}>
                        {counterparty_name}
                      </p>
                      <p>{counterparty_iban}</p>
                    </div>
                    <div className={styles.remittance_information}>
                      {remittance_information}
                    </div>
                    <div
                      className={classNames(
                        styles.amount,
                        amount < 0 && styles.payout,
                      )}>
                      {centsToPrice(amount)}
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
