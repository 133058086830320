import React from 'react';

import styles from './LegalLinks.module.scss';

export default function LegalLinks({className}) {
  return (
    <div className={styles.legal}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href="https://getmomo.de/impressum"
        className={styles.link}
        target="_blank">
        <p className={className}>Impressum </p>
      </a>

      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href="https://www.getmomo.de/datenschutz"
        className={styles.link}
        target="_blank">
        <p className={className}>| Datenschutz</p>
      </a>
    </div>
  );
}
