import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import {getApiUrl} from 'shared/utils.js';
import Icon from 'shared/components/Icon.js';

import styles from './CustomerFeedback.module.scss';
import {ReactComponent as MomoFeedbackSvg} from './momo_customer_feedback.svg';

const {REACT_APP_API_URL} = process.env;

export default function CustomerFeedback({minimized}) {
  const customer_feedback_url = new URL(
    'customerFeedback',
    getApiUrl(REACT_APP_API_URL),
  );

  return (
    <a
      className={classNames(styles.feedback_button, text_styles.body2_left)}
      rel="noopener noreferrer"
      target="_blank"
      href={customer_feedback_url.toString()}>
      {!minimized && <span>Funktionen anfragen</span>}
      <Icon className={minimized && styles.icon}>
        <MomoFeedbackSvg />
      </Icon>
    </a>
  );
}
