import {getApiUrl} from './utils.js';

const {REACT_APP_API_URL} = process.env;

// const API_URL = getApiUrl(REACT_APP_API_URL);

export const CONFLICT = 'CONFLICT';
export const BAD_REQUEST = 'BAD_REQUEST';
export const NOT_FOUND = 'NOT_FOUND';
export const FORBIDDEN = 'FORBIDDEN';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const MANAGER_PROFILE_INCOMPLETE = 'MANAGER_PROFILE_INCOMPLETE';

export async function request(path, options = {}, API_URL = REACT_APP_API_URL) {
  const headers = new Headers(options.headers);

  if (options.body instanceof URLSearchParams) {
    options.body = options.body.toString();
    if (!headers.has('content-type')) {
      headers.set(
        'content-type',
        'application/x-www-form-urlencoded;charset=UTF-8',
      );
    }
    // TODO: only JSON.stringify if the constructor
    // is Object or null instead
  } else if (typeof options.body === 'object' && !isBlob(options.body)) {
    options.body = JSON.stringify(options.body);
    if (!headers.has('content-type')) {
      headers.set('content-type', 'application/json;charset=UTF-8');
    }
  }

  return fetch(new URL(path, new URL(getApiUrl(API_URL))), {
    ...options,
    headers,
  });
}

export async function RPC(method, params, {headers, API_URL, signal} = {}) {
  const res = await request(
    method,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headers,
      },
      signal,
      body: params,
    },
    API_URL,
  );

  let result, error;

  if (res.status === 401) {
    error = {
      code: UNAUTHORIZED,
    };
  } else if (res.ok) {
    ({result, error} = await res.json());
  } else {
    error = new Error(`${res.status} ${res.statusText}`);
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(method, error);
    throw error;
  }

  return result;
}

// Node.js only
export async function login(url, username, password) {
  const res = await fetch(new URL('login', url), {
    method: 'POST',
    body: JSON.stringify({email_address: username, password}),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  if (!res.ok) {
    // eslint-disable-next-line no-console
    console.error(res.status, res.statusText);
    // eslint-disable-next-line unicorn/no-process-exit
    process.exit(1);
  }

  return res.headers.get('set-cookie');
}

function isBlob(data) {
  return typeof Blob !== 'undefined' && data instanceof Blob;
}
