import {ReactComponent as AddIcon} from 'shared/components/add_circle_primary.svg';
import Button from 'shared/components/Button.js';
import IconButton from 'shared/components/IconButton.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import Input from 'shared/components/Input.js';
import Select from 'shared/components/Select';

import {ReactComponent as TrashSvg} from '../../assets/trash.svg';
import {gender_select_options} from '../../lib/utils.js';

import styles from './PropertyOwnerDialog.module.scss';
import coowner_form_styles from './CoownerFormSection.module.scss';

export default function CoownerFormSection({
  errors,
  register,
  watch,
  setValue,
}) {
  const has_coowner = watch('has_coowner');

  if (!has_coowner) {
    return (
      <div>
        <Button
          onClick={() => {
            setValue('has_coowner', true, {shouldDirty: true});
            setValue('coowner_first_name', null, {shouldDirty: true});
            setValue('coowner_last_name', null, {shouldDirty: true});
            setValue('coowner_gender', null, {shouldDirty: true});
          }}
          title="Zweite Person hinzufügen"
          className={coowner_form_styles.add_second_person_button}
          Icon={AddIcon}
        />
      </div>
    );
  }

  return (
    <>
      <div className={styles.separator} />
      <div className={classNames(styles.row, coowner_form_styles.header)}>
        <p className={text_styles.caption_left}>Zweite Person</p>
        <IconButton
          onClick={() => {
            setValue('has_coowner', false, {shouldDirty: true});
            setValue('coowner_first_name', null, {shouldDirty: true});
            setValue('coowner_last_name', null, {shouldDirty: true});
            setValue('coowner_gender', null, {shouldDirty: true});
          }}>
          <TrashSvg />
        </IconButton>
      </div>
      <div>
        <div className={styles.row}>
          <Input
            label="Vorname"
            error={errors.coowner_first_name?.message}
            className={styles.margin_right}
            {...register('coowner_first_name')}
          />
          <Input
            label="Nachname"
            error={errors.coowner_last_name?.message}
            {...register('coowner_last_name')}
          />
        </div>

        <div className={styles.row}>
          <Select
            label="Geschlecht"
            options={gender_select_options}
            error={errors.coowner_gender?.message}
            {...register('coowner_gender')}
          />
        </div>
      </div>
    </>
  );
}
