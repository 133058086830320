import React from 'react';
import {useStore, useSelector} from 'react-redux/lib/index.js';

import {initializeEffects} from '../../effects.js';

import ConfirmModal from './ConfirmModal.js';
import AlertModal from './AlertModal.js';
import OverlayModal from './OverlayModal.js';

const MODAL_COMPONENTS = {
  ALERT: AlertModal,
  CONFIRM: ConfirmModal,
  OVERLAY: OverlayModal,
};

export default function ModalConnector() {
  const store = useStore();

  const {name, visible, props} = useSelector((state) => {
    return state.modal;
  });

  const {onDialogClosed, closeModal} = initializeEffects(store);

  if (!name) return null;

  const ModalComponent = MODAL_COMPONENTS[name];

  return (
    <ModalComponent
      show={visible}
      onExited={onDialogClosed}
      onHide={closeModal}
      {...props}
    />
  );
}
