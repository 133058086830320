import {Controller} from 'react-hook-form';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import PhoneNumberInput from 'shared/components/phoneNumberInput/PhoneNumberInput.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Input from 'shared/components/Input.js';
import SwitchToggle from 'shared/components/switchToggle/SwitchToggle.js';
import classNames from 'classnames/bind';
import Button from 'shared/components/Button.js';
import {INVITATION_METHOD} from 'shared/constants.js';

import SelectRentalContract from '../../components/SelectRentalContract.js';

import styles from './TenantData.module.scss';

export default function TenantData({
  rental_contract,
  errors,
  register,
  control,
  watch,
}) {
  const invitation_method = watch('invitation_method');

  return (
    <>
      <Controller
        control={control}
        name="rental_contract_id"
        render={({field: {value, onChange}}) => {
          return (
            <SelectRentalContract
              label="Hauptmieter"
              value={value}
              rpc_method="getDepositGuaranteeOptions"
              onChange={onChange}
              error={errors.rental_contract_id?.message}
            />
          );
        }}
      />

      <Input
        type="text"
        label="Beschreibung (optional)"
        placeholder="z. B. ID-123"
        error={errors.given_reference?.message}
        {...register('given_reference')}
      />

      <InvitationMethod
        invitation_method={invitation_method}
        control={control}
        rental_contract={rental_contract}
        errors={errors}
      />

      <Controller
        control={control}
        name="gdpr_consent"
        defaultValue={false}
        render={({field: {value, onChange, name}}) => {
          return (
            <Checkbox
              id={name}
              name={name}
              textStyle={text_styles.body2_left}
              value={value}
              onChange={onChange}
              error={errors[name]?.message}
              label={
                'Der Mieter möchte ein Angebot für die Mietkautionsbürgschaft von Getmomo erhalten und hat in die Kontaktaufnahme eingewilligt.'
              }
            />
          );
        }}
      />
      <>
        <div className={styles.separator} />
        <div className={text_styles.body1_bold_left}>Weitere Angaben:</div>
      </>

      <Controller
        control={control}
        name="tenant_provides_additional_data"
        defaultValue={rental_contract.tenant_provides_additional_data || false}
        render={({field: {value, onChange, name}}) => (
          <SwitchToggle
            label="Angaben zur Mieteinheit und zum Eigentümer vom Mieter eintragen lassen"
            className={styles.switch_toggle}
            id={name}
            value={value}
            onChange={onChange}
            error={errors[name]?.message}
          />
        )}
      />

      {watch('tenant_provides_additional_data') && (
        <div
          className={classNames(
            text_styles.body1_bold_left,
            styles.text_additional_data_toggle,
          )}>
          Wir fragen den Namen und die{' '}
          <span className={styles.highlight}>Adresse des Eigentümers</span>{' '}
          sowie die für den Bürgschaftsvertrag benötigten Angaben zur Wohnung
          beim Mieter an.
        </div>
      )}
    </>
  );
}

function InvitationMethod({
  invitation_method,
  control,
  rental_contract,
  errors,
}) {
  return (
    <>
      <div className={styles.header_row}>
        <div className={text_styles.caption_left}>
          {invitation_method === INVITATION_METHOD.email
            ? 'E-Mail für die Einladung'
            : 'Telefonnummer für die SMS-Einladung'}
        </div>

        <Controller
          control={control}
          name="invitation_method"
          defaultValue={INVITATION_METHOD.sms}
          render={({field: {value, onChange}}) => {
            return (
              <Button
                type="button"
                onClick={() => {
                  onChange(
                    value === INVITATION_METHOD.email
                      ? INVITATION_METHOD.sms
                      : INVITATION_METHOD.email,
                  );
                }}
                title={
                  value === INVITATION_METHOD.email
                    ? 'Einladung per SMS senden'
                    : 'Einladung per E-Mail senden'
                }
                className={classNames(text_styles.caption_right, styles.button)}
              />
            );
          }}
        />
      </div>

      {(invitation_method === INVITATION_METHOD.sms || !invitation_method) && (
        <Controller
          control={control}
          name="tenant_phone_number"
          defaultValue={rental_contract.tenant_phone_number || ''}
          render={({field: {value, onChange, name}}) => (
            <PhoneNumberInput
              value={value}
              onChange={onChange}
              error={errors[name]?.message}
            />
          )}
        />
      )}

      {invitation_method === INVITATION_METHOD.email && (
        <Controller
          control={control}
          name="tenant_email_address"
          defaultValue={rental_contract.tenant_email_address || ''}
          render={({field: {value, onChange, name}}) => (
            <Input
              value={value}
              onChange={onChange}
              error={errors[name]?.message}
            />
          )}
        />
      )}
    </>
  );
}
